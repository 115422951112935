import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@proman/services/local-storage.service';
import { isDefined } from '@proman/utils';
import moment from 'moment';
import { getPublicSystemOptions } from '@proman/store/system-options';
import { Store } from '@ngrx/store';
import { RequestService } from '@proman/services/request.service';

const LOGGER_KEY = 'LOGGER';
const LOGGING_URL = 'https://master.proman.lt/api/public/container_log';
const DATE_FORMAT = 'YYYY-MM-DD HH:mm';
enum LogLevel {
    LOG = 'log',
    WARN = 'warn',
    ERROR = 'error',
};

declare interface LoggerRequest {
    text: string,
    level: LogLevel,
    date: string,
    url: string,
    namespace?: string,
}

/*
       $text = $request->request->get('text') ?? throw new Error('No text provided');
        $level = $request->request->get('level') ?? throw new Error('No level provided');
        $date = $request->request->get('date') ?? throw new Error('No date provided');
        $url = $request->request->get('url') ?? throw new Error('No url provided');

 */

declare interface LoggerServiceTimeMap {
    [key: string]: {
        default: number;
        warn: number|null;
        error: number|null;
    }
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
    store = inject(Store);
    LocalStorage = inject(LocalStorageService);
    Request = inject(RequestService);
    isLogger: boolean;
    timeMap: LoggerServiceTimeMap = {};
    namespace = '';

    constructor() {
        this.isLogger = isDefined(this.LocalStorage.get(LOGGER_KEY));
        this.store.select(getPublicSystemOptions)
            .subscribe((value) => {
                if (value && value.namespace) {
                    this.namespace = value.namespace;

                    if (value.corporateChild) {
                        //TODO workaroundas corporate vaikams, kol bus dedikuotas laukas teviniams konteineriams
                        this.namespace = value.database;
                    }
                }
        })
    }

    log(...data: any[]) {
        if (this.isLogger) console.log('LOGGER', ...data);
    }

    track(key: string, warnTime: number|null = null, errorTime: number|null = null) {
        this.timeMap[key] = {
            'default': Date.now(),
            warn: warnTime,
            error: errorTime,
        };
    }

    trackComplete(key: string) {
        const time = moment.duration(Date.now() - this.timeMap[key]['default']).asSeconds();
        const errorTime = this.timeMap[key]['error'];
        const warnTime = this.timeMap[key]['warn'];
        const text = `action '${key}' complete in ${time}s`;
        this.log(text);
        if (errorTime && time > errorTime) {
            console.log('should send error log request');
            this.logRequest(text, LogLevel.ERROR)
                .then((response) => {
                    console.log('Logging response', response);
                    console.log('Logged: ', this.getRequest(text, LogLevel.ERROR));
                });
        } else if (warnTime && time > warnTime) {
            console.log('should send warning log request');
            this.logRequest(text, LogLevel.WARN)
                .then((response) => {
                    console.log('Logging response', response);
                    console.log('Logged: ', this.getRequest(text, LogLevel.ERROR));
                });
        }
        delete this.timeMap[key];
    }

    logRequest(text: string, level: LogLevel): Promise<unknown> {
        return this.Request.post(LOGGING_URL, this.getRequest(text, level))
            .catch((err) => {
                console.log(`Error logging ${level} '${text}':`, err);
            });
    }

    getRequest(text: string, level: LogLevel): LoggerRequest {
        const url = window.location.href;
        const request = {
            text,
            level,
            date: moment().utc().format(DATE_FORMAT),
            url
        };

        if (url.includes('localhost') && this.namespace) {
            request['namespace'] = this.namespace;
        }

        return request;
    }

}
