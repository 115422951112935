import { InventorizationByDate, PromanFile } from '../interfaces/entity-interfaces';
import { EntityInterface } from '../services/entity.service';

export const inventorization_by_date = {
  name: 'inventorization_by_date',
  params: {
    entityConfig: {
      name: 'inventorization_by_date',
      post: [
        'getMaterialsList',
        'createInventorizationDocument',
        'getOrdersMaterials',
      ],
    }
  }
};

export interface InventorizationByDateEntityInterface extends EntityInterface<InventorizationByDate> {
  getMaterialsList: (data: { id: number, warehouse: number, inventorizationDate: string }) => Promise<{ materialId: number, quantId: number, name: string, unit: string, quantity: number }[]>;
  createInventorizationDocument: (data: { template: number, inventorizationByDate: number }) => Promise<[PromanFile, string]>;
  getOrdersMaterials: (data: { from: string, to: string }) => Promise<object[]>;
}
