import { EntityInterface } from '../services/entity.service';
import { Container } from '../interfaces/entity-interfaces';

export const container = {
  name: 'container',
  params: {
      entityConfig: {
          name: 'container',
          post: ['updateContainer', 'containerLog', 'updateContainerDb', 'restartContainer']
      }
  }
};

export interface ContainerEntityInterface extends EntityInterface<Container> {
    updateContainer: (data: { id: number, updateVersion?: string }) => Promise<any>;
    containerLog: (data: { id: number }) => Promise<any>;
    updateContainerDb: (data: { id: number, db: string }) => Promise<any>;
    restartContainer: (data: { id: number }) => Promise<number>;
}
